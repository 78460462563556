import React from "react";

const BathroomIcon = () => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.495 7.232H3.9C3.9 6.75733 4.26 6.37333 4.7075 6.37333H11.69C12.135 6.37333 12.4975 6.75733 12.4975 7.232H12.495ZM3.6575 0.672C3.6575 0.672 2.2 0.032 0.5 0.672V1.04267H3.6925C3.685 0.909333 3.6725 0.784 3.6575 0.672V0.672ZM12.255 8.63467H12.41C12.455 8.416 12.52 7.992 12.495 7.552H3.47C3.5775 6.98933 3.7625 3.47467 3.7025 1.27467H0.5V7.616H0.52C0.6025 7.848 0.95 8.63467 1.875 8.63467H1.9425C2.1125 9.43467 2.8575 10.088 3.195 10.544C3.9 11.5013 4.02 11.9707 4.0925 13.1413C4.195 14.7707 3.51 16 3.51 16H11.2075C11.2325 15.5333 10.7775 14.5387 10.5775 13.9653C10.2125 12.9333 10.795 11.896 11.16 11.496C12.015 10.5627 12.135 9.432 12.255 8.63467V8.63467ZM2.3075 0H1.885C1.7175 0 1.585 0.144 1.585 0.32H2.61C2.61 0.144 2.475 0 2.31 0H2.3075Z"
        fill="#666666"
      />
    </svg>
  );
};

export default BathroomIcon;
