import React from "react";

const FloorIcon = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.3534 8.03407L14.0079 7.80371L7.93425 12.243C7.80766 12.3354 7.65756 12.3844 7.50038 12.3844C7.3432 12.3844 7.1931 12.3354 7.06651 12.243L0.99259 7.80371L0.647079 8.03407C0.456198 8.16115 0.450251 8.43686 0.635184 8.57206L7.27891 13.4279C7.34462 13.476 7.4225 13.5001 7.5001 13.5001C7.57769 13.5001 7.65558 13.476 7.72128 13.4279L14.365 8.57206C14.5499 8.43686 14.544 8.16115 14.3531 8.03407H14.3534Z" fill="#666666"/>
    <path d="M0.635307 5.53777L7.30197 10.4107C7.36088 10.4538 7.43055 10.4753 7.50022 10.4753C7.56989 10.4753 7.63955 10.4538 7.69846 10.4107L14.3651 5.53777C14.5501 5.40257 14.5441 5.12686 14.3532 4.99978L7.68657 0.556261C7.63021 0.518754 7.56535 0.5 7.50022 0.5C7.43508 0.5 7.37023 0.518754 7.31387 0.556261L0.647201 5.00006C0.45632 5.12714 0.450373 5.40285 0.635307 5.53805V5.53777Z" fill="#666666"/>
    <path d="M14.3534 6.51698L14.0079 6.28662L7.93425 10.7259C7.80766 10.8183 7.65756 10.8673 7.50038 10.8673C7.3432 10.8673 7.1931 10.8183 7.06651 10.7259L0.99259 6.28662L0.647079 6.51698C0.456198 6.64406 0.450251 6.91977 0.635184 7.05497L7.30185 11.9279C7.36076 11.971 7.43043 11.9925 7.5001 11.9925C7.56976 11.9925 7.63943 11.971 7.69834 11.9279L14.365 7.05497C14.5499 6.91977 14.544 6.64406 14.3531 6.51698H14.3534Z" fill="#666666"/>
    </svg>
    
    
  );
};

export default FloorIcon;
