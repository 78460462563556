
import React from "react";

const PoolIcon = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6891 6.0061H1.31092C0.863141 6.0061 0.5 6.36883 0.5 6.81611C0.5 7.26338 0.863141 7.62611 1.31092 7.62611H11.6891C12.1369 7.62611 12.5 7.26338 12.5 6.81611C12.5 6.36883 12.1369 6.0061 11.6891 6.0061Z" fill="#666666"/>
    <path d="M11.6891 8.18262H1.31092C0.863141 8.18262 0.5 8.54535 0.5 8.99262C0.5 9.43989 0.863141 9.80262 1.31092 9.80262H11.6891C12.1369 9.80262 12.5 9.43989 12.5 8.99262C12.5 8.54535 12.1369 8.18262 11.6891 8.18262Z" fill="#666666"/>
    <path d="M12.5 11.1909C12.5 10.7436 12.1369 10.3809 11.6891 10.3809H1.31092C0.863141 10.3809 0.5 10.7436 0.5 11.1909C0.5 11.6381 0.863141 12.0009 1.31092 12.0009H11.6891C12.1369 12.0009 12.5 11.6381 12.5 11.1909Z" fill="#666666"/>
    <path d="M4.29268 1.817L5.9746 1.81155C6.3159 1.81155 6.58893 1.53336 6.58893 1.19518C6.58893 0.856998 6.31317 0.581543 5.97187 0.581543L4.28995 0.586998C3.15411 0.589725 2.22852 1.517 2.22852 2.65155V3.16155C2.22852 4.30155 3.15685 5.2261 4.29541 5.2261H7.86129C8.07426 5.2261 8.27904 5.30792 8.43194 5.45246H9.97734L9.52955 4.84155C9.14184 4.31246 8.51658 3.9961 7.86129 3.9961H4.29541C3.83398 3.9961 3.45992 3.62246 3.45992 3.16155V2.65155C3.45992 2.19336 3.83398 1.81973 4.29268 1.817Z" fill="#666666"/>
    <path d="M10.7172 0.840685C10.4906 0.816139 10.2667 0.794321 10.04 0.769775L10.0455 1.65069C10.2694 1.6316 10.4933 1.60978 10.7144 1.59069V0.840685H10.7172Z" fill="#666666"/>
    <path d="M7.62101 0.412525C7.62101 0.338889 7.66743 0.289798 7.73023 0.289798C7.79576 0.289798 7.8449 0.338889 7.84763 0.415253C7.84763 0.606162 7.84763 0.797072 7.84763 0.990708C7.84763 1.06707 7.79849 1.11889 7.73296 1.11616C7.66743 1.11344 7.62101 1.06434 7.62101 0.987981C7.62101 0.892526 7.62101 0.799799 7.62101 0.704344C7.62101 0.606162 7.62101 0.510707 7.62101 0.412525ZM7.44354 2.37071C7.7821 2.45253 8.10429 2.20162 8.10702 1.8498C8.10702 1.84162 8.10702 1.83344 8.10702 1.82526C8.57392 1.78162 9.04354 1.74071 9.51044 1.69707L9.50225 0.712526C9.03808 0.663435 8.57119 0.614344 8.10702 0.565253C8.10702 0.554344 8.10702 0.540708 8.10702 0.529798C8.10429 0.257071 7.89951 0.0334337 7.63466 0.00343362C7.35617 -0.0265664 7.11043 0.142525 7.04763 0.412525C7.03671 0.461616 7.03125 0.513435 7.03125 0.562526C7.03125 0.982527 7.03125 1.40253 7.03125 1.82526C7.03125 2.09798 7.19507 2.31344 7.44081 2.37071H7.44354Z" fill="#666666"/>
    </svg>
    
    
  );
};

export default PoolIcon;
