import React from "react";

const ServiceIcon = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.01505 15.2186L9.33505 14.152C10.583 13.1449 10.451 11.819 9.90205 10.2458L9.91105 10.2399L7.12705 6.84024C5.68405 5.96724 4.38805 5.56202 3.13405 6.57804L1.81405 7.64471C0.17305 8.97059 0.0530502 11.5449 1.54405 13.3684L2.31805 14.3158C3.80905 16.1393 6.37405 16.5475 8.01505 15.2216V15.2186ZM2.21605 12.7099C1.49605 11.8309 1.39105 10.6659 1.86805 9.81379L5.83105 14.6525C4.89505 14.9445 3.76705 14.6019 3.04705 13.7229L2.21605 12.7099V12.7099ZM11.639 7.76984L12.728 7.96649L13.295 7.50765L13.259 6.6853L14.156 5.97022L14.834 5.8123L14.909 4.75755L15.515 4.29573L16.766 4.42087L16.958 4.26593L17.069 3.44657L17.84 3.55085L18.185 3.27078L18.491 0.681577L17.93 -0.000732422L16.598 0.0111856L7.72405 7.18586L10.1 10.0879L11.408 9.03018L11.639 7.76984ZM8.30305 7.86519L17.696 0.279342L18.125 0.803737L8.73205 8.38958L8.30305 7.86817V7.86519Z"
        fill="#666666"
      />
    </svg>
  );
};

export default ServiceIcon;
