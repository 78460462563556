
import React from "react";

const InterIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.02748 0.5H11.8533C12.6964 0.5 13.3808 1.20306 13.3808 2.06905V3.69286C13.3808 4.55884 12.6964 5.2619 11.8533 5.2619H2.02748C1.18444 5.2619 0.5 4.55884 0.5 3.69286V2.06905C0.5 1.20306 1.18444 0.5 2.02748 0.5Z" fill="#666666"/>
<path d="M14.3957 2.3042C14.1378 2.3042 13.9262 2.51883 13.9262 2.78379C13.9262 3.04876 14.1258 3.26338 14.3838 3.26338C14.4633 3.26338 14.5394 3.31814 14.5394 3.40692V5.88413C14.5394 5.95624 14.4924 6.02223 14.4192 6.03753C14.4156 6.03821 14.4159 6.03923 14.4123 6.03991L7.42386 7.67733C6.92088 7.78515 6.55962 8.24331 6.55962 8.76951V10.33H6.48611C6.13744 10.33 5.86426 10.6212 5.86426 10.9794V14.8518C5.86426 15.21 6.13744 15.5001 6.48611 15.5001H7.53479C7.88346 15.5001 8.18214 15.2096 8.18214 14.8518V10.9794C8.18214 10.6212 7.88346 10.33 7.53479 10.33H7.48677V8.76951C7.48677 8.6957 7.54174 8.63141 7.61426 8.6161C7.6179 8.61542 7.62287 8.6144 7.62651 8.61372L14.6235 6.97631C15.1285 6.86814 15.4997 6.40998 15.4997 5.88413V3.40692C15.4997 2.7991 15.0017 2.3042 14.3957 2.3042V2.3042Z" fill="#666666"/>
</svg>

  );
};

export default InterIcon;
