import React from "react";
import CarpetIcon from "../../Icons/carpet-icon";
import ElectricIcon from "../../Icons/electric-icon";
import FloorIcon from "../../Icons/floor-repair";
import SellerIcon from "../../Icons/seller-icon";
import KitchenIcon from "../../Icons/kitchen-icon";
import BathroomIcon from "../../Icons/bathroom-icon";
import FencingIcon from "../../Icons/fencing-icon";
import CustomIcon from "../../Icons/custom-icon";
import ServiceIcon from "../../Icons/service-icon";
import PestIcon from "../../Icons/pest-icon";
import SewerIcon from "../../Icons/sewer-icon";
import HvacIcon from "../../Icons/hvac-icon";
import LandscapeIcon from "../../Icons/landscape-icon";
import CheckboxIcon from "../../Icons/checkbox.tsx";
import WaterIcon from "../../Icons/water-icon";
import StagingIcon from "../../Icons/staging-icon";
import CleaningIcon from "../../Icons/cleaning-icon";
import PoolIcon from "../../Icons/pool-icon";
import CostIcon from "../../Icons/cost-icon";
import MoveIcon from "../../Icons/move-icon";
import DeclutIcon from "../../Icons/declut-icon";
import RoofIcon from "../../Icons/roof-icon";
import InterIcon from "../../Icons/inter-icon";

const optionData = [
  {
    icon: <CarpetIcon />,
    tag: "Carpet Cleaning & Replacements",
  },
  {
    icon: <LandscapeIcon />,
    tag: "Landscaping",
  },
  {
    icon: <FencingIcon />,
    tag: "Fencing",
  },
  {
    icon: <PestIcon />,
    tag: "Pest Control",
  },
  {
    icon: <FloorIcon />,
    tag: "Floor Repair",
  },
  {
    icon: <InterIcon />,
    tag: "Interior & Exterior Painting",
  },
  {
    icon: <ElectricIcon />,
    tag: "Electrical Work",
  },
  {
    icon: <WaterIcon />,
    tag: "Water Heating & Plumbing Repair",
  },
  {
    icon: <StagingIcon />,
    tag: "Staging",
  },
  {
    icon: <HvacIcon />,
    tag: "HVAC",
  },
  {
    icon: <SellerIcon />,
    tag: "Seller-side Inspections & Evaluations",
  },
  {
    icon: <SewerIcon />,
    tag: "Sewer Lateral Inspections & Remediations",
  },
  {
    icon: <CleaningIcon />,
    tag: "Deep Cleaning",
  },
  {
    icon: <RoofIcon />,
    tag: "Roofing Repair",
  },

  {
    icon: <KitchenIcon />,
    tag: "Kitchen Improvements",
  },
  {
    icon: <ServiceIcon />,
    tag: "+100 Other Home Improvement Services!",
  },
  {
    icon: <DeclutIcon />,
    tag: "Decluttering",
  },
  {
    icon: <MoveIcon />,
    tag: "Moving & Storage",
  },
  {
    icon: <BathroomIcon />,
    tag: "Bathroom Improvements",
  },
  {
    icon: <CostIcon />,
    tag: "Cosmetic Renovations",
  },
  {
    icon: <CustomIcon />,
    tag: "Custom Closet Work",
  },

  {
    icon: <PoolIcon />,
    tag: "Pool & Tennis Court Services",
  },
];

const TheOption = () => {
  return (
    <>
      <div className="bg-[#fff] py-[60px]">
        <div className="container">
          <div className="text-center pb-[48px]">
            <h2 className="font-IbarraRealNova text-[#1A1A1A] xs:text-ms-4xl text-d-2xl font-[700] pb-[24px]">
              The Options
            </h2>
            <p className="text-d-3lg text-[#666666] font-[500] font-Quicksand">
              Get help with whatever you need to make your home sell—everything{" "}
              <br />
              from cleaning, repairs, landscaping, staging, and much more.
            </p>
          </div>

          <div>
            <ul className="w-full flex flex-wrap font-[600] font-Quicksand ">
              {optionData.map((value) => {
                return (
                  <>
                    <li className="max-w-[25%] w-full mb-[16px] 2xs:max-w-[50%] xs:px-[8px]">
                      <div className="flex items-center max-w-[230px] w-full">
                        {/* <span className="2xs_extar:hidden">
                          <CheckboxIcon />
                        </span> */}
                        <div className="block  2xs_extar:h-[16px] ">
                          {value.icon}
                        </div>
                        <p className="ml-[16px] text-[14px] text-[#666666]">{value.tag}</p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TheOption;
