import React from "react";

const CleaningIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.42949 9.88736C10.3604 11.3928 12.5131 11.4037 13.4586 9.90918C13.604 9.68372 13.7459 9.60008 14.0077 9.69099C13.684 10.7019 12.5604 11.4474 11.415 11.4328C10.2913 11.4219 9.19313 10.6655 8.89495 9.68736C9.12768 9.61463 9.2804 9.64372 9.42949 9.88372V9.88736ZM8.46222 9.05827C8.34222 9.09099 8.24768 9.24736 8.23677 9.35281C8.2295 9.44008 8.31313 9.56372 8.43677 9.63281C8.49859 9.66554 8.59677 9.66554 8.69495 9.68009C8.7204 10.0219 8.73495 10.3564 8.77495 10.691C8.78586 10.7892 8.84404 10.9164 8.9204 10.971C9.39677 11.3164 9.59313 11.7819 9.5895 12.3601C9.58222 13.4583 9.5895 14.5528 9.5895 15.6474V15.9928C10.8949 15.9928 12.1459 16.0001 13.4004 15.9892C13.7131 15.9892 13.8259 15.7637 13.844 15.4764C13.9022 14.5419 13.9568 13.6037 14.0149 12.6692C14.0768 11.6801 14.1386 10.6946 14.204 9.67645C14.3131 9.6619 14.3931 9.65463 14.4513 9.63281C14.564 9.58918 14.684 9.5019 14.6659 9.33463C14.655 9.22554 14.5313 9.06918 14.4004 9.04372C14.3022 9.02554 14.2768 9.03645 14.215 9.03645C12.3713 9.03645 10.524 9.03645 8.6804 9.03645C8.60768 9.03645 8.55313 9.02918 8.46586 9.05099L8.46222 9.05827Z" fill="#666666"/>
<path d="M10.395 8.62182C10.7768 8.62182 11.0859 8.30546 11.0859 7.91273C11.0859 7.52 10.7768 7.20728 10.395 7.20728C10.0132 7.20728 9.7041 7.52364 9.7041 7.91273C9.7041 8.30182 10.0132 8.62182 10.395 8.62182Z" fill="#666666"/>
<path d="M11.8958 5.90918C11.594 5.90918 11.3467 6.16009 11.3467 6.46918C11.3467 6.77827 11.594 7.02918 11.8958 7.02918C12.1976 7.02918 12.4449 6.77827 12.4449 6.46918C12.4449 6.16009 12.1976 5.90918 11.8958 5.90918Z" fill="#666666"/>
<path d="M12.7796 8.62187C13.0014 8.62187 13.1832 8.43641 13.1832 8.21096C13.1832 7.9855 13.0014 7.80005 12.7796 7.80005C12.5578 7.80005 12.376 7.9855 12.376 8.21096C12.376 8.43641 12.5578 8.62187 12.7796 8.62187Z" fill="#666666"/>
<path d="M2.47818 7.17452C2.36182 7.15998 2.30727 7.10907 2.26364 7.00361C2.15455 6.71998 2.03091 6.43998 1.91091 6.15998C1.89636 6.12361 1.85636 6.09816 1.82727 6.06543C1.80545 6.10179 1.76909 6.13816 1.76182 6.17816C1.70727 6.49088 1.65273 6.80361 1.60545 7.11998C1.59091 7.20725 1.55818 7.25088 1.47818 7.28361C1.19455 7.39634 0.914546 7.51634 0.634545 7.63998C0.583636 7.66179 0.547273 7.70543 0.5 7.74179C0.550909 7.76725 0.601818 7.80361 0.66 7.81452C0.954545 7.86907 1.25273 7.91998 1.55091 7.96361C1.64182 7.97816 1.68909 8.01816 1.72182 8.10179C1.83818 8.3927 1.96182 8.67998 2.08909 8.96725C2.10727 9.00725 2.14364 9.03634 2.17273 9.06907C2.19455 9.02907 2.23455 8.9927 2.24182 8.94907C2.3 8.6327 2.35091 8.3127 2.40182 7.99634C2.41273 7.92361 2.44182 7.88725 2.51091 7.86179C2.80182 7.74179 3.08909 7.61816 3.37636 7.49816C3.41273 7.48361 3.44545 7.46543 3.48545 7.45088C3.49273 7.4327 3.50727 7.39998 3.5 7.39634C3.47455 7.37452 3.44182 7.34543 3.40909 7.33816C3.1 7.28361 2.79091 7.22907 2.48182 7.18543L2.47818 7.17452Z" fill="#666666"/>
<path d="M4.88926 4.43635C4.74017 4.41089 4.59108 4.3818 4.44199 4.36362C4.38744 4.35635 4.35835 4.33089 4.34017 4.27998C4.28562 4.1418 4.22744 4.00726 4.16926 3.87271C4.16199 3.85453 4.1438 3.84362 4.12926 3.82544C4.11835 3.84362 4.10017 3.85817 4.10017 3.87998C4.07471 4.03271 4.04562 4.1818 4.0238 4.33453C4.01653 4.37453 4.00199 4.39998 3.96199 4.41453C3.8238 4.46908 3.68926 4.52726 3.55471 4.58544C3.52926 4.59635 3.51108 4.61817 3.48926 4.63271C3.51471 4.64362 3.54017 4.6618 3.56562 4.66908C3.70744 4.69453 3.85289 4.71998 3.99471 4.7418C4.03835 4.74908 4.06017 4.76726 4.07835 4.80726C4.13653 4.94908 4.19471 5.08726 4.25653 5.22544C4.2638 5.24362 4.28199 5.25817 4.29653 5.27271C4.30744 5.25453 4.32562 5.23635 4.32926 5.21453C4.35835 5.0618 4.3838 4.90908 4.40562 4.75271C4.40926 4.71635 4.42744 4.7018 4.45653 4.68726C4.59835 4.62908 4.73653 4.57089 4.87471 4.51271C4.89289 4.50544 4.90744 4.49817 4.92562 4.49089C4.92562 4.47998 4.93653 4.46544 4.93289 4.46544C4.92199 4.45453 4.9038 4.43998 4.88926 4.43635Z" fill="#666666"/>
<path d="M6.49522 0.734546C6.49522 0.567273 6.62976 0.429091 6.79703 0.429091C6.96431 0.429091 7.10249 0.563636 7.10249 0.730909C7.10249 0.898182 6.96794 1.03636 6.80067 1.03636C6.6334 1.03636 6.49522 0.901818 6.49522 0.734546ZM6.89885 10.3527C7.12431 10.3527 7.30612 10.1236 7.30612 9.84364L7.3534 0.523636C7.35703 0.232727 7.16794 0 6.93885 0H6.66249C6.42976 0.00363636 6.24794 0.243636 6.25522 0.530909L6.48431 9.84727C6.49158 10.1273 6.67703 10.3527 6.90249 10.3491L6.89885 10.3527Z" fill="#666666"/>
<path d="M4.70224 13.0036L4.7277 16H5.25497L6.10952 14.0836L6.1277 15.9891L7.49497 15.9782V14.6L8.21133 15.9709L8.93861 15.9636L8.91315 12.9673C8.90224 11.8036 7.95315 10.8691 6.78952 10.88C5.62588 10.8909 4.69133 11.84 4.70224 13.0036Z" fill="#666666"/>
</svg>

    
  );
};

export default CleaningIcon;
