import React from "react";

const PercentageIcon = () => {
  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_1219_6728)">
        <rect y="0.5" width="38" height="38" rx="3" fill="#0D1733" />
      </g>
      <g clip-path="url(#clip0_1219_6728)">
        <path
          d="M16.4737 19.3608C17.2963 18.5798 17.7131 17.4583 17.7131 16.0288C17.7131 14.5992 17.3 13.4312 16.4885 12.6644C15.6659 11.8905 14.6183 11.5 13.3752 11.5C12.0104 11.5 10.9555 11.9407 10.2362 12.8149C9.53163 13.6641 9.17383 14.7031 9.17383 15.9069C9.17383 17.4368 9.57589 18.6048 10.3726 19.3752C11.1731 20.1526 12.1949 20.5503 13.3974 20.5503C14.5999 20.5503 15.6438 20.1491 16.4737 19.3608V19.3608ZM12.217 18.1749C11.8703 17.7915 11.6969 17.0965 11.6969 16.1076C11.6969 15.0148 11.8703 14.266 12.2133 13.879C12.5453 13.5028 12.9473 13.3237 13.4379 13.3237C13.9285 13.3237 14.3343 13.5028 14.67 13.8754C15.0167 14.2588 15.1901 14.9575 15.1901 15.9535C15.1901 17.0391 15.0167 17.7844 14.6736 18.1713C14.3417 18.5475 13.9359 18.7302 13.4379 18.7302C12.94 18.7302 12.5527 18.5475 12.217 18.1785V18.1749Z"
          fill="white"
        />
        <path
          d="M27.7756 22.6141C26.9567 21.8402 25.9128 21.4496 24.666 21.4496C23.3012 21.4496 22.2389 21.8903 21.5196 22.7574C20.8187 23.603 20.4609 24.6456 20.4609 25.8566C20.4609 27.3793 20.863 28.5437 21.6561 29.3176C22.4565 30.0987 23.4746 30.4964 24.6771 30.4964C25.8796 30.4964 26.9346 30.0951 27.7608 29.3069C28.5797 28.5258 28.9965 27.4044 28.9965 25.9748C28.9965 24.5453 28.5834 23.3772 27.7719 22.6105L27.7756 22.6141ZM25.9681 28.121C25.6361 28.4972 25.2304 28.6763 24.7214 28.6763C24.2123 28.6763 23.8398 28.4936 23.5041 28.1174C23.1574 27.7304 22.984 27.0354 22.984 26.0536C22.984 24.9609 23.1574 24.212 23.5004 23.8251C23.8324 23.4489 24.2345 23.2662 24.7361 23.2662C25.2378 23.2662 25.6325 23.4453 25.9681 23.8179C26.3149 24.2013 26.4882 24.9 26.4882 25.896C26.4882 26.9816 26.3149 27.7268 25.9718 28.1138L25.9681 28.121Z"
          fill="white"
        />
        <path
          d="M27.709 11.6362L20.0291 14.1979L22.0432 15.8604L9 29.7082L12.6038 29.7154L24.0904 17.5515L25.9532 19.0886L27.709 11.6362Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1219_6728"
          x="-12"
          y="-11.5"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1219_6728"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1219_6728"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1219_6728">
          <rect
            width="20"
            height="19"
            fill="white"
            transform="translate(9 11.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PercentageIcon;
