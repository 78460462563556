import React, {useContext} from "react";
import Button from "../../Button/Button";
import { Link } from "gatsby";
import {IframeVideo} from './style';
import ModalContext from "../../context/ModalContext";
const MarketingHome = () => {
  const { setModalvisible, setPopupContent } = useContext(ModalContext);

  return (
    <>
      <div className="pt-[140px] pb-[91px] md:pt-[132px] sm:pt-0 bg-[#F0F0F0] sm:pb-[24px] ">
        <div className="container md:p-0">
          <div className="flex gap-[54px] md:gap-[33px] items-start justify-between md:flex-col-reverse md:justify-start md:items-start">
            <div className="max-w-[400px] md:px-[17px] w-full lg:mr-[30px] md:max-w-[500px] md:max-w-[100%] md:w-full md:mr-[0px] md:mb-[50px]">
              <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-600 pb-24">
                Hassle-Free Prep. <br /> 
                <span className="font-Quicksand font-500">
                  No Upfront Costs.
                </span>
              </h1>
              <p className="text-d-3lg font-500 font-Quicksand pb-[24px] text-[#666666]">
                It’s no secret that refreshing and staging a home you’re selling
                can maximize its value so you can command a higher price. Ask us
                about adding Compass Concierge to your real estate marketing
                plan. There’s nothing due now—you pay at closing, with no
                interest.
              </p>
                <Button
                onClick={() => {
                  setModalvisible(true)
                  setPopupContent('steps')
                }}
                  type="solid"
                  color="white"
                  customStyle="px-[42px] xs:px-[0] xs:w-full xs:justify-center"
                >
                  <p>Contact</p>
                </Button>
            </div>
            <div className="w-full">
              <IframeVideo
                className="w-full h-[406px] lg:h-[28vw] md:h-[50vw]"
                src="https://www.youtube.com/embed/Cmtw6xEZV8A"
                title="Compass Concierge"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></IframeVideo>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingHome;
