import React from "react";

const MoveIcon = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.16543 13.2293C7.9025 13.2293 6.63958 13.2304 5.37666 13.2293C4.88321 13.2293 4.83282 13.1784 4.83228 12.686C4.83228 10.2125 4.83228 7.73905 4.83228 5.26611C4.83228 4.81048 4.8854 4.75691 5.33723 4.75691C7.88607 4.75583 10.4349 4.75529 12.9838 4.75583C13.4307 4.75583 13.498 4.8213 13.498 5.2634C13.4986 7.75907 13.498 10.2553 13.4975 12.751C13.4975 13.1476 13.4192 13.2282 13.0227 13.2288C11.7367 13.2309 10.4514 13.2293 9.16543 13.2293Z" fill="#666666"/>
<path d="M3.05238 11.4079C3.05238 11.2585 3.05238 11.1476 3.05238 11.0372C3.05238 8.50581 3.04964 5.97443 3.05512 3.44304C3.05512 3.22551 3.00364 3.06371 2.83989 2.90841C2.23964 2.34077 1.65364 1.75852 1.0638 1.18114C0.781748 0.905165 0.740126 0.743909 0.904974 0.585901C1.0638 0.433303 1.22098 0.47984 1.50631 0.759061C2.1208 1.36079 2.72871 1.96902 3.35524 2.55884C3.57814 2.7688 3.67179 2.98742 3.6707 3.29586C3.65974 5.98471 3.66686 8.67356 3.66029 11.3624C3.66029 11.5718 3.71506 11.7049 3.88648 11.8424C4.60502 12.4203 4.79561 13.1784 4.57545 14.0967C4.72277 14.1048 4.85202 14.1178 4.98127 14.1178C6.3477 14.1195 7.71413 14.1184 9.08057 14.1189C9.18298 14.1189 9.30347 14.0919 9.38288 14.1351C9.48694 14.192 9.63371 14.3137 9.62549 14.3938C9.61399 14.5047 9.49296 14.6319 9.38507 14.6963C9.30018 14.7472 9.16326 14.7169 9.0499 14.7169C7.54709 14.7174 6.04375 14.7185 4.54094 14.7158C4.35309 14.7158 4.20303 14.7434 4.05297 14.8878C3.32512 15.5897 2.26757 15.6947 1.45264 15.1682C0.639355 14.6422 0.294324 13.6297 0.62402 12.7358C0.959741 11.8256 1.86011 11.2888 2.86891 11.3976C2.91273 11.4025 2.95654 11.4025 3.05348 11.4079H3.05238ZM2.5792 14.89C3.39194 14.8911 4.06502 14.2363 4.06557 13.4441C4.06612 12.6519 3.39194 11.9815 2.59179 11.9782C1.78398 11.9755 1.11254 12.6346 1.11199 13.4311C1.11144 14.2396 1.76427 14.8889 2.57865 14.89H2.5792Z" fill="#666666"/>
<path d="M2.59865 14.0603C2.2569 14.0674 1.96828 13.7876 1.96499 13.4456C1.96171 13.0998 2.24102 12.819 2.58769 12.8201C2.91794 12.8212 3.19177 13.0868 3.20382 13.4175C3.21587 13.7627 2.94258 14.0533 2.59919 14.0609L2.59865 14.0603Z" fill="#666666"/>
<rect x="7.49915" y="4.69971" width="3" height="1.5" fill="white"/>
</svg>

  );
};

export default MoveIcon;
