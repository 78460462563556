
import React from "react";

const WaterIcon = () => {
  return (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4757 4.41406H4.74191C2.53956 4.41406 0.695933 6.15064 0.453429 8.45089C0.452542 8.45588 0.446627 8.48581 0.408181 8.57934C0.291365 8.86368 0.295801 9.18574 0.420011 9.46291C0.540376 9.73134 0.764249 9.93493 1.03455 10.0163C1.12032 10.0422 1.21407 10.0571 1.32999 10.0571H3.27624C3.39868 10.0571 3.51756 10.0241 3.62965 9.96486C4.04604 9.74381 4.25365 9.22409 4.12323 8.73087C4.08153 8.57342 4.0907 8.49704 4.0975 8.48207C4.34267 8.01223 4.80786 7.71916 5.31121 7.71916H12.4757C12.5831 7.71916 12.6768 7.62875 12.6768 7.51558V4.62077C12.6768 4.50759 12.5834 4.41469 12.4757 4.41469V4.41406Z" fill="#666666"/>
    <path d="M2.21359 11.0396C2.15622 11.0396 2.1018 11.0661 2.06483 11.1122C1.93412 11.2753 0.787842 12.7272 0.787842 13.4969C0.787842 14.3256 1.42723 15 2.21329 15C2.99936 15 3.63875 14.3256 3.63875 13.4969C3.63875 12.7272 2.49247 11.2753 2.36176 11.1122C2.32479 11.0661 2.27037 11.0396 2.213 11.0396H2.21359Z" fill="#666666"/>
    <path d="M6.38324 1.87001C6.77007 1.87001 7.09892 1.60812 7.21545 1.234H7.91546V1.957C7.23526 2.0365 6.73251 2.62855 6.73251 3.34563V3.65304C6.73251 3.76621 6.8159 3.85289 6.92355 3.85289H9.71797C9.82533 3.85289 9.92676 3.76652 9.92676 3.65304V3.34189C9.92676 2.62575 9.39444 2.03432 8.74381 1.95637V1.23368H9.42401C9.54053 1.60781 9.86939 1.8697 10.2562 1.8697C10.74 1.8697 11.1337 1.4566 11.1337 0.946541C11.1337 0.436482 10.7394 0.0155886 10.2556 0.0155886C9.8688 0.0155886 9.54024 0.298678 9.42342 0.641628H8.74322V0.439599C8.74322 0.196728 8.55957 0 8.32919 0C8.09881 0 7.91516 0.196728 7.91516 0.439599V0.641628H7.21515C7.09863 0.298678 6.76977 0.0165239 6.38295 0.0165239C5.89912 0.0165239 5.50549 0.433052 5.50549 0.943112C5.50549 1.45317 5.89912 1.8697 6.38295 1.8697L6.38324 1.87001Z" fill="#666666"/>
    </svg>
  );
};

export default WaterIcon;
