import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import MarketingService from "../component/MarketingService/index";
const MarketingServices = () => {
  return (
      <LayoutWrapper
      title="Real Estate Marketing Services | The Selby Team"
      desc="Give your home a refresh and proper staging to maximize its value with Compass Concierge real estate marketing services. Call The Selby Team to learn more."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <MarketingService />
    </LayoutWrapper>
  );
};

export default MarketingServices;
