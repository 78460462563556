import React from "react";

const keyIcon = () => {
  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_1219_6753)">
        <rect y="0.5" width="38" height="38" rx="3" fill="#0D1733" />
      </g>
      <path
        d="M18.0251 30.4247L19.7859 28.9581C21.4508 27.5735 21.2747 25.7506 20.5423 23.5877L20.5543 23.5795L16.8405 18.9054C14.9155 17.7052 13.1866 17.1481 11.5138 18.545L9.75294 20.0115C7.56385 21.8344 7.40377 25.3737 9.39276 27.8808L10.4253 29.1834C12.4143 31.6905 15.836 32.2517 18.0251 30.4288V30.4247ZM10.2892 26.9755C9.32873 25.767 9.18866 24.1653 9.82497 22.9937L15.1116 29.6463C13.863 30.0478 12.3582 29.5767 11.3978 28.3682L10.2892 26.9755V26.9755ZM22.8595 20.1835L24.3122 20.4539L25.0686 19.8231L25.0205 18.6924L26.2171 17.7093L27.1216 17.4922L27.2216 16.042L28.03 15.4071L29.6989 15.5791L29.955 15.3661L30.1031 14.2396L31.1316 14.383L31.5918 13.9979L32 10.4381L31.2516 9.5L29.4747 9.51639L17.6369 19.3806L20.8064 23.3706L22.5513 21.9163L22.8595 20.1835ZM18.4092 20.3146L30.9395 9.88507L31.5118 10.606L18.9815 21.0356L18.4092 20.3187V20.3146Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_1219_6753"
          x="-12"
          y="-11.5"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1219_6753"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1219_6753"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default keyIcon;
