import React from "react";

const CarpetIcon = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83329 13.4563C6.87011 12.8169 7.11147 12.2539 7.54102 11.7714C8.33056 10.8787 9.87692 10.4002 11.3292 11.3371C11.3333 11.2768 11.3456 11.2245 11.3415 11.1723C11.3251 10.6696 11.3415 10.163 11.276 9.66837C11.2147 9.15769 10.9037 8.89229 10.376 8.88023C9.46374 8.8561 8.55556 8.86012 7.64329 8.8561C7.03374 8.8561 6.42011 8.836 5.81874 8.97271C4.67329 9.23409 3.70374 9.82117 2.81602 10.5611C2.76692 10.6053 2.71374 10.6455 2.6442 10.6978C2.43556 9.99408 2.46829 9.33461 2.80783 8.69928C3.06556 8.21272 3.43783 7.81061 3.87556 7.48088C4.43192 7.05464 5.01283 6.6646 5.58965 6.26651C6.08874 5.92069 6.58374 5.56683 6.98465 5.10441C8.14647 3.75733 7.98692 1.70657 6.63692 0.636951C5.61011 -0.175313 4.19465 -0.211503 3.04511 0.548487C2.28829 1.05113 1.77283 1.7508 1.36783 2.53492C1.22056 2.81639 1.10192 3.11396 0.962833 3.42358C1.30647 3.53215 1.60511 3.62866 1.92829 3.73321C1.95692 3.66887 1.98147 3.60855 2.00601 3.54824C2.27192 2.9129 2.59511 2.31375 3.08192 1.81514C3.54011 1.34467 4.07601 1.01091 4.76329 0.99483C5.76147 0.970703 6.60829 1.71059 6.72692 2.68772C6.82101 3.48792 6.59192 4.18357 5.97011 4.70632C5.48738 5.11245 4.95965 5.46631 4.44011 5.82418C3.85102 6.23032 3.26192 6.63243 2.75874 7.13909C2.18192 7.71813 1.73192 8.36955 1.57647 9.18583C1.43738 9.91768 1.53147 10.6254 1.79738 11.317C1.84238 11.4377 1.83011 11.51 1.7442 11.6065C1.32283 12.077 0.917833 12.5595 0.63556 13.1265C0.426924 13.5407 0.304197 13.975 0.36556 14.4414C0.45556 15.1532 1.04056 15.6719 1.78511 15.6839C2.61965 15.7 3.45829 15.688 4.29283 15.692C5.37283 15.692 6.45283 15.692 7.53283 15.692C7.5942 15.692 7.65147 15.688 7.74556 15.6799C7.10738 15.0486 6.78829 14.3208 6.83738 13.4603L6.83329 13.4563Z"
        fill="#666666"
      />
    </svg>
  );
};

export default CarpetIcon;
