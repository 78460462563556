

import React from "react";

const KitchenIcon = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.20385 9.43151H2.18923L2.06462 10H1.32846L1.20385 9.43151ZM13.9192 10H14.6577L14.7823 9.43151H13.7969L13.9215 10H13.9192ZM12.0246 9.22375H0.5V0H12.0177C12.4446 1.54338 12.7031 3.08219 12.7285 4.61872C12.7538 6.15297 12.5415 7.68722 12.0269 9.22375H12.0246ZM11.3138 0.767123H1.69538V8.38585H11.3138V8.32192C12.0269 5.67123 11.8631 3.41553 11.3138 0.76484V0.767123ZM10.4231 2.36758C10.2569 2.41781 10.1623 2.59132 10.2038 2.76027C10.5062 3.97717 10.5062 5.24658 10.2038 6.46347C10.1623 6.63242 10.2569 6.80822 10.4231 6.85616C10.5892 6.90639 10.7692 6.7968 10.8154 6.61187C11.1431 5.2968 11.1431 3.92466 10.8154 2.60959C10.7692 2.42466 10.5892 2.31507 10.4231 2.3653V2.36758ZM15.5 0V9.22375H12.3062C12.7908 7.68493 12.9892 6.14612 12.9638 4.60502C12.9385 3.06621 12.6915 1.53196 12.2831 0H15.5V0ZM13.5223 3.09589C13.5223 3.34475 13.7277 3.54795 13.9792 3.54795H14.3577C14.6092 3.54795 14.8146 3.34475 14.8146 3.09589V2.72146C14.8146 2.4726 14.6092 2.26941 14.3577 2.26941H13.9792C13.7277 2.26941 13.5223 2.4726 13.5223 2.72146V3.09589ZM13.5223 5.45434C13.5223 5.7032 13.7277 5.90639 13.9792 5.90639H14.3577C14.6092 5.90639 14.8146 5.7032 14.8146 5.45434V5.07991C14.8146 4.83105 14.6092 4.62785 14.3577 4.62785H13.9792C13.7277 4.62785 13.5223 4.83105 13.5223 5.07991V5.45434ZM14.9715 7.58448H13.1623L12.9938 8.69407H14.9692V7.58448H14.9715Z" fill="#666666"/>
    </svg>
    
    
  );
};

export default KitchenIcon;

