import React from "react";

const ElectricIcon = () => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.29709 14.9391V9.22092H6.02951V12.0025H6.36661V9.22092H9.5L7.57922 0H2.41835L0.5 9.22092H4.72716V14.9391H3.8274L3.00525 16H7.01657L6.19442 14.9391H5.29709Z"
        fill="#666666"
      />
    </svg>
  );
};

export default ElectricIcon;
