import React, {useContext} from "react";
import HomeWorthMobile from "";
import { Link } from "gatsby";
import Button from "../../Button/Button";
import PercentageIcon from "../../Icons/percentage-icon";
import HomeIcon from "../../Icons/home-icon";
import KeyIcon from "../../Icons/key-icon";
import ThumbsupIcon from "../../Icons/thumbs-up";
import ModalContext from "../../context/ModalContext";
import { StaticImage } from "gatsby-plugin-image";
const MakeEasyData = [
  {
    icon: <PercentageIcon />,
    tag: "We Help With the Plan",
    descrip:
      "As your San Diego Compass agent, we’ll help you determine which services can deliver the greatest return on your investment."
  },
  {
    icon: <HomeIcon />,
    tag: "We’re Entirely Transparent",
    descrip:
      "There are no surprises or hidden fees, and you'll never have to worry about upfront costs or interest."
  },
  {
    icon: <ThumbsupIcon />,
    tag: "We Advise You Throughout",
    descrip:
      "Your Selby real estate professional will be by your side throughout the process, advising you along the way."
  },
  {
    icon: <KeyIcon />,
    tag: "The Work Gets Done",
    descrip:
      "The entire process is designed for speed so work can begin and you can sell your home as quickly as possible."
  }
];

const MakeItEasy = () => {
  const { setModalvisible, setPopupContent } = useContext(ModalContext);

  return (
    <>
      <div className="pt-[20px] bg-[#fff]">
        <div className="container">
          <div className="">
            <h2 className="pb-[64px] font-IbarraRealNova text-d-2xl text-[#1A1A1A] xs:text-ms-4xl font-[700] text-center">
              How <span className="font-Quicksand font-[500]">We Make It Easy</span> For
              You
            </h2>
          </div>

          <div className="flex flex-wrap justify-between pb-[65px]">
            {MakeEasyData.map(value => {
              return (
                <>
                  <div className="w-full max-w-[535px] mb-[56px]">
                    <div className="mb-[12px]">{value.icon}</div>
                    <h4 className="mb-[12px] text-d-4lg font-[700] font-Quicksand">
                      {value.tag}
                    </h4>
                    <p className="text-[#666666] text-d-3lg font-Quicksand">
                      {value.descrip}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div className="next_home_card relative">
          <StaticImage
            src="../../../images/homeWorth.png"
            alt="relativeimg"
            className="block 2xs_extar:!hidden w-full"
          />

          <StaticImage
            src="../../../images/homeWorthMobile.png"
            alt="relativeimg"
            className="!hidden 2xs_extar:!block w-full"
          />

          <div className="text-center pt-26 pl-44 pb-66 pr-44 absolute right-0 left-0 .max-w-screen-sm top-2/4 -translate-y-1/2 mb-2 bottom-0 2xs_extar:pb-19  2xs_extar:pt-0 ">
            <h3 className="font-IbarraRealNova text-d-2xl font-700 text-white pb-16 2xs:text-ms-4xl">
              What’s Your Home Worth?
            </h3>
            <p className="font-Quicksand text-d-3lg text-[#F7F9FA]  font-500 pb-16">
              There’s no robot or algorithm here—you get an accurate valuation
              from humans based on our expertise and the newest MLS data
            </p>
            <Link to="#" className=" 2xs_extar:max-w-[48%] 2xs_extar:w-full">
              <Button
              onClick={() => {
                setModalvisible(true)
                setPopupContent('homeValuation')
              }}
                type="solid"
                customStyle="px-[25px] 2xs_extar:px-[0] 2xs_extar:w-3/5"
              >
                <p>Get Estimate</p>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeItEasy;
