
import React from "react";

const StagingIcon = () => {
  return (
    <svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4959 2.91043C18.4959 2.46748 18.1686 2.11401 17.7677 2.11401C17.3545 2.11401 17.0314 2.46748 17.0314 2.92386C17.0314 3.64869 17.0314 4.378 17.0314 5.10283V5.23258H1.96455V5.17442C1.96455 4.44511 1.96455 3.7158 1.96455 2.9865C1.96455 2.91491 1.96455 2.83885 1.94818 2.76726C1.88273 2.3601 1.53909 2.07822 1.14636 2.11849C0.794545 2.14981 0.5 2.50328 0.5 2.90149C0.5 4.1856 0.5 5.46972 0.5 6.75384V6.83885H18.4959C18.4959 6.83885 18.5 6.80305 18.5 6.78963C18.5 6.40037 18.5 6.00663 18.5 5.61737C18.5 4.71804 18.5 3.81424 18.5 2.91491L18.4959 2.91043Z" fill="#666666"/>
    <path d="M2.70089 4.48094H8.97634C8.97634 4.26618 8.97634 4.06036 8.97634 3.85455C8.97634 2.78519 8.97634 1.71137 8.97634 0.642018C8.97634 0.516739 8.9518 0.395933 8.88634 0.288551C8.75952 0.0916827 8.5877 0.00219727 8.3668 0.00219727C7.29907 0.00219727 6.23134 0.00219727 5.16361 0.00219727C4.46816 0.00219727 3.76861 0.00219727 3.06907 0.00219727C2.72543 0.00219727 2.48816 0.270654 2.48816 0.646493C2.48816 1.09839 2.48816 1.5503 2.48816 1.99772C2.48816 2.03352 2.50043 2.07826 2.52498 2.10958C2.64361 2.28408 2.70089 2.48094 2.70089 2.70018C2.70089 3.25947 2.70089 3.82323 2.70089 4.38251V4.47647V4.48094Z" fill="#666666"/>
    <path d="M15.8044 0.0021429C15.3912 0.0110914 14.974 0.0021429 14.5608 0.0021429C13.2231 0.0021429 11.8812 0.0021429 10.5435 0.0021429C10.4821 0.0021429 10.4167 0.0021429 10.3594 0.02004C10.0935 0.0961026 9.92578 0.337713 9.92578 0.63749C9.92578 1.8187 9.92578 2.99991 9.92578 4.18111C9.92578 4.27955 9.92578 4.37798 9.92578 4.48089H16.2053V4.4093C16.2053 3.84107 16.2053 3.27284 16.2053 2.7046C16.2053 2.48536 16.2626 2.29297 16.3812 2.114C16.4017 2.08268 16.4181 2.03346 16.4181 1.99319C16.4181 1.55472 16.414 1.11176 16.4181 0.673284C16.4262 0.301919 16.1644 -0.00680565 15.8044 0.0021429Z" fill="#666666"/>
    <path d="M3.9973 7.48779H2.7373V7.99786H3.9973V7.48779Z" fill="#666666"/>
    <path d="M15.0636 7.99778H16.3236V7.49219H15.0636V7.99778Z" fill="#666666"/>
    </svg>
    
  );
};

export default StagingIcon;
