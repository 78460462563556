import React from "react";

const FencingIcon = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4248 16V1.93895C14.4248 1.30342 14.8969 0.72968 15.5325 0.582567V0.370725C15.5325 0.197131 15.6773 0 15.8566 0C16.036 0 16.1808 0.200074 16.1808 0.370725V0.582567C16.8164 0.732622 17.2885 1.30636 17.2885 1.93895V16M13.2731 15.9941H14.2171V14.3141H13.2731V15.9941ZM13.2731 3.6278H14.2171V1.94777H13.2731V3.6278ZM13.0654 16V1.93895C13.0654 1.30342 12.5934 0.72968 11.9577 0.582567V0.370725C11.9577 0.197131 11.8129 0 11.6336 0C11.4542 0 11.3094 0.200074 11.3094 0.370725V0.582567C10.6738 0.732622 10.2017 1.30636 10.2017 1.93895V16M9.04685 15.9941H9.99091V14.3141H9.04685V15.9941ZM9.07832 3.6278H10.0224V1.94777H9.07832V3.6278ZM8.83916 16V1.93895C8.83916 1.30342 8.36713 0.72968 7.73147 0.582567V0.370725C7.73147 0.197131 7.58671 0 7.40734 0C7.22797 0 7.08322 0.200074 7.08322 0.370725V0.582567C6.44755 0.732622 5.97552 1.30636 5.97552 1.93895V16M4.82063 15.9941H5.76469V14.3141H4.82063V15.9941ZM4.8521 3.6278H5.79615V1.94777H4.8521V3.6278ZM4.61294 16V1.93895C4.61294 1.30342 4.14091 0.72968 3.50524 0.582567V0.370725C3.50524 0.197131 3.36049 0 3.18112 0C3.00175 0 2.85699 0.200074 2.85699 0.370725V0.582567C2.22133 0.732622 1.7493 1.30636 1.7493 1.93895V16M0.5 15.9941H1.54476V14.3141H0.506294V15.9941H0.5ZM0.506294 3.66605H1.54476V1.97426H0.506294V3.66605ZM17.4678 3.66605H18.5063V1.97426H17.4678V3.66605ZM17.4678 15.9765H18.5063V14.2847H17.4678V15.9765Z"
        fill="#666666"
      />
    </svg>
  );
};

export default FencingIcon;
