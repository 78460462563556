import React from "react";

const ThumbsupIcon = () => {
  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_1219_6746)">
        <rect y="0.5" width="38" height="38" rx="3" fill="#0D1733" />
      </g>
      <g clip-path="url(#clip0_1219_6746)">
        <path
          d="M12.0955 30.2923L8.05119 23.4224C7.92653 23.2075 8.03286 22.9174 8.28953 22.7705L11.5272 20.9474C11.6519 20.8757 11.7949 20.8507 11.9269 20.8757C12.0589 20.9008 12.1652 20.9689 12.2239 21.0727L16.2682 27.9462C16.3929 28.1611 16.2865 28.4512 16.0299 28.5981L12.7922 30.4212C12.6675 30.4893 12.5245 30.5179 12.3925 30.4928C12.2605 30.4678 12.1542 30.3997 12.0955 30.2958V30.2923ZM11.5199 21.9861L9.22086 23.2827L12.8069 29.3789L15.1059 28.0823L11.5199 21.9861ZM12.8142 27.9319C12.9865 28.2148 13.3642 28.3044 13.6539 28.136C13.9435 27.9677 14.0352 27.5988 13.8629 27.3158L13.1185 26.1051C12.9462 25.8222 12.5685 25.7326 12.2789 25.901C11.9892 26.0693 11.8975 26.4383 12.0699 26.7212L12.8142 27.9319V27.9319ZM13.1552 21.7175L16.1655 26.7893C16.4112 27.2012 16.8989 27.3695 17.3169 27.1295L17.3535 27.108C17.6762 26.9254 18.1162 27.0006 18.6809 26.9755H20.2979L21.3319 26.9719H23.5135L26.0509 26.9612C26.7732 26.9612 27.3562 26.3415 27.3562 25.5858C27.3562 24.9769 26.9785 24.4647 26.4579 24.282H27.0115C27.7339 24.282 28.3169 23.6624 28.3169 22.903C28.3169 22.3658 28.0235 21.9037 27.5982 21.6781H27.8805C28.6029 21.6781 29.1859 21.0584 29.1859 20.2991C29.1859 19.683 28.7972 19.1601 28.2655 18.9881H28.6945C29.4169 18.9881 29.9999 18.3685 29.9999 17.6092C29.9999 16.8498 29.4132 16.2373 28.6909 16.2373L22.8499 16.2481C22.6519 15.5031 22.7875 14.9049 23.0112 14.5754C23.3632 14.0596 24.3202 13.2071 24.1405 11.778C24.0819 11.3052 23.7922 10.5745 23.4952 10.1984C22.6482 9.12033 21.4162 9.37105 21.4162 10.3417C21.4162 11.4951 20.9505 13.2071 19.5975 14.0811C17.3792 15.5138 16.0959 18.1894 16.0959 18.1966C16.0959 18.2037 15.8172 18.4831 15.4762 18.8198L13.4265 20.5641C13.0049 20.8005 12.9132 21.2984 13.1552 21.7103V21.7175Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1219_6746"
          x="-12"
          y="-11.5"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1219_6746"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1219_6746"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1219_6746">
          <rect
            width="22"
            height="21"
            fill="white"
            transform="translate(8 9.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThumbsupIcon;
