import React from 'react'
import MakeItEasy from './MakeItEasy/MakeItEasy'
import MarketingHome from './MarketingHome/MarketingHome'
import TheOption from './TheOption/TheOption'
import MoveYourFamily from '../../component/Home/MoveYourFamily/MoveYourFamily';



const MarketingService = () => {
  return (
    <>
      <MarketingHome />
      <TheOption />
      <MakeItEasy />
      <MoveYourFamily />
    </>
  )
}

export default MarketingService
