import React from "react";

const HvacIcon = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6991 7.62043C17.1327 7.62043 17.4812 7.2586 17.4812 6.82501V6.50316C17.4812 6.06957 17.1327 5.7074 16.6991 5.7074H16.5245V5.3705C16.5245 4.6236 15.9297 3.99902 15.1828 3.99902H2.78819C2.04129 3.99902 1.42252 4.6236 1.42252 5.3705V5.7074H1.28209C0.848508 5.7074 0.5 6.06957 0.5 6.50316V6.82501C0.5 7.2586 0.848508 7.62043 1.28209 7.62043H1.42252V12.5405H1.28209C0.848508 12.5405 0.5 12.8781 0.5 13.3117V13.6336C0.5 14.0671 0.848508 14.4194 1.28209 14.4194H1.42252V14.5369C1.42252 15.2838 2.04129 15.8886 2.78819 15.8886H15.1828C15.9297 15.8886 16.5245 15.2842 16.5245 14.5369V14.4194H16.6991C17.1327 14.4194 17.4812 14.0671 17.4812 13.6336V13.3117C17.4812 12.8781 17.1327 12.5405 16.6991 12.5405H16.5245V7.62043H16.6991ZM5.21511 13.4385C5.21511 13.735 4.97457 13.9756 4.678 13.9756H4.14635C3.84978 13.9756 3.60924 13.735 3.60924 13.4385V6.55167C3.60924 6.2551 3.84978 6.01456 4.14635 6.01456H4.678C4.97457 6.01456 5.21511 6.2551 5.21511 6.55167V13.4385ZM8.22184 13.4385C8.22184 13.735 7.9813 13.9756 7.68473 13.9756H7.15309C6.85651 13.9756 6.61597 13.735 6.61597 13.4385V6.55167C6.61597 6.2551 6.85651 6.01456 7.15309 6.01456H7.68473C7.9813 6.01456 8.22184 6.2551 8.22184 6.55167V13.4385ZM11.3311 13.4385C11.3311 13.735 11.0905 13.9756 10.794 13.9756H10.2623C9.96575 13.9756 9.72521 13.735 9.72521 13.4385V6.55167C9.72521 6.2551 9.96575 6.01456 10.2623 6.01456H10.794C11.0905 6.01456 11.3311 6.2551 11.3311 6.55167V13.4385ZM14.372 13.4385C14.372 13.735 14.1314 13.9756 13.8349 13.9756H13.3032C13.0067 13.9756 12.7661 13.735 12.7661 13.4385V6.55167C12.7661 6.2551 13.0067 6.01456 13.3032 6.01456H13.8349C14.1314 6.01456 14.372 6.2551 14.372 6.55167V13.4385Z"
        fill="#666666"
      />
      <path
        d="M8.58676 2.98183C8.68038 3.11508 8.82969 3.18649 8.98139 3.18649C9.07706 3.18649 9.17375 3.15813 9.25815 3.09902C9.4758 2.94595 9.52841 2.64562 9.37534 2.42797C9.32375 2.35417 9.23867 2.2175 9.252 2.12798C9.26225 2.06067 9.32546 1.96671 9.39277 1.86729C9.58923 1.57618 9.95516 1.03394 9.35621 0.290801C9.18913 0.083746 8.88607 0.0509452 8.67867 0.218024C8.47161 0.385103 8.43881 0.688168 8.60589 0.895564C8.70224 1.01515 8.72274 1.08485 8.72001 1.10501C8.7142 1.1508 8.64758 1.2492 8.59393 1.32847C8.39371 1.62504 8.02095 2.17719 8.58676 2.98217V2.98183Z"
        fill="#666666"
      />
      <path
        d="M6.78512 2.98183C6.87874 3.11508 7.02805 3.18649 7.17976 3.18649C7.27543 3.18649 7.37212 3.15813 7.45651 3.09902C7.67416 2.94595 7.72678 2.64562 7.57371 2.42797C7.52211 2.35417 7.43704 2.2175 7.45036 2.12798C7.46061 2.06067 7.52382 1.96671 7.59113 1.86729C7.7876 1.57618 8.15353 1.03394 7.55457 0.290801C7.38749 0.083746 7.08443 0.0509452 6.87703 0.218024C6.66998 0.385103 6.63718 0.688168 6.80426 0.895564C6.90061 1.01515 6.92111 1.08485 6.91838 1.10501C6.91257 1.1508 6.84594 1.2492 6.7923 1.32847C6.59208 1.62504 6.21931 2.17719 6.78512 2.98217V2.98183Z"
        fill="#666666"
      />
      <path
        d="M10.3888 2.98183C10.4824 3.11508 10.6317 3.18649 10.7834 3.18649C10.8791 3.18649 10.9758 3.15813 11.0602 3.09902C11.2778 2.94595 11.3304 2.64562 11.1773 2.42797C11.1258 2.35417 11.0407 2.2175 11.054 2.12798C11.0643 2.06067 11.1275 1.96671 11.1948 1.86729C11.3912 1.57618 11.7572 1.03394 11.1582 0.290801C10.9911 0.083746 10.6881 0.0509452 10.4807 0.218024C10.2736 0.385103 10.2408 0.688168 10.4079 0.895564C10.5042 1.01515 10.5247 1.08485 10.522 1.10501C10.5162 1.1508 10.4496 1.2492 10.3959 1.32847C10.1957 1.62504 9.82295 2.17719 10.3888 2.98217V2.98183Z"
        fill="#666666"
      />
    </svg>
  );
};

export default HvacIcon;
