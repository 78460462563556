import React from "react";

const LandscapeIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.42456 12.8064V15.3126C3.42456 15.5459 3.61055 15.7349 3.84386 15.7349C4.07717 15.7349 4.26315 15.5459 4.26315 15.3126V12.8064C4.11341 12.8211 3.98582 12.8289 3.84296 12.8289C3.7001 12.8289 3.54436 12.8214 3.42456 12.8067V12.8064Z"
        fill="#666666"
      />
      <path
        d="M9.52805 5.08727C8.74846 5.08727 8.02248 5.31579 7.413 5.70903C7.61097 6.6767 7.72567 7.60754 7.72567 8.34191C7.72567 9.6627 7.34052 10.7891 6.61633 11.6016C7.27163 12.3324 8.1866 12.8149 9.23484 12.8907V9.99514C9.23484 9.98766 9.21867 9.97987 9.20999 9.97118L8.24291 8.99482C8.1884 8.94001 8.16174 8.86843 8.16174 8.79655C8.16174 8.72407 8.18989 8.65159 8.2453 8.59649C8.35582 8.48717 8.53162 8.48777 8.64124 8.59798L9.23484 9.19878V7.48535C9.23484 7.32751 9.36393 7.19963 9.51937 7.19963C9.67481 7.19963 9.80389 7.32751 9.80389 7.48535V10.9724L10.5472 10.2341C10.6575 10.1245 10.8357 10.1254 10.945 10.2356C10.9995 10.2904 11.0264 10.362 11.0264 10.4336C11.0264 10.5061 10.9992 10.5786 10.9438 10.6337L9.80389 11.7655V12.891C11.8405 12.7499 13.4353 11.0593 13.4353 8.99362C13.4353 6.92798 11.6856 5.08667 9.52805 5.08667V5.08727Z"
        fill="#666666"
      />
      <path
        d="M9.08508 13.4443V15.3129C9.08508 15.5462 9.28605 15.7352 9.51936 15.7352C9.75266 15.7352 9.95363 15.5462 9.95363 15.3129V13.4455C9.80388 13.4581 9.66581 13.4647 9.52624 13.4647C9.38668 13.4647 9.23483 13.4578 9.08508 13.4443V13.4443Z"
        fill="#666666"
      />
      <path
        d="M3.83341 0.265137C1.99479 0.265137 0.5 5.84718 0.5 8.3417C0.5 10.7083 1.83726 12.1157 3.5444 12.254V9.86075L2.12568 8.43784C2.07057 8.38273 2.04541 8.31085 2.04541 8.23867C2.04541 8.16649 2.07386 8.09461 2.12897 8.03951C2.23889 7.92959 2.41349 7.92959 2.52341 8.03951L3.5444 9.06439V3.53655C3.5444 3.38111 3.67348 3.25503 3.82892 3.25503C3.98436 3.25503 4.11344 3.38111 4.11344 3.53655V6.29044L4.90831 5.49647C5.01823 5.38655 5.19703 5.38655 5.30694 5.49647C5.36205 5.55158 5.3896 5.62346 5.3896 5.69563C5.3896 5.76781 5.36115 5.83969 5.30604 5.8948L4.11314 7.0868V12.254C5.82028 12.1148 7.16114 10.7074 7.16114 8.3417C7.16143 5.84718 5.67203 0.265137 3.83341 0.265137Z"
        fill="#666666"
      />
    </svg>
  );
};

export default LandscapeIcon;
