import React from "react";

const SellerIcon = () => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5689 7.17364C9.61288 7.17364 8.71444 6.79309 8.03946 6.09818C7.36449 5.40564 6.99129 4.48382 6.99129 3.50291C6.99129 2.78673 7.19171 2.09891 7.5626 1.51273L6.16197 0.0756365C6.11589 0.0283638 6.05139 0 5.98689 0C5.92238 0 5.85788 0.0260002 5.81181 0.0756365L1.00633 5.00855C0.960261 5.05582 0.932617 5.122 0.932617 5.18818V10.4733C0.932617 10.6127 1.04319 10.7285 1.18141 10.7285H4.3052C4.44112 10.7285 4.554 10.6151 4.554 10.4733V7.84727C4.554 6.812 5.0585 6.16909 5.98919 6.16909C6.91988 6.16909 7.42438 6.812 7.42438 7.84727V10.4733C7.42438 10.6127 7.53496 10.7285 7.67318 10.7285H10.797C10.9329 10.7285 11.0458 10.6151 11.0458 10.4733V7.14055C10.8891 7.16182 10.7325 7.17127 10.5712 7.17127L10.5689 7.17364Z" fill="#666666"/>
    </svg>
    
  );
};

export default SellerIcon;
