
import React from "react";

const RoofIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.55275 6.48063C4.18594 6.44785 3.98152 6.54697 3.86041 6.88832C3.66331 7.44374 3.40913 7.9791 3.17308 8.52063C2.98061 8.96227 2.63772 9.10151 2.20418 8.9237C1.8987 8.79834 1.75291 8.59083 1.81963 8.26491C2.01557 7.30797 2.2308 6.35489 2.44641 5.4022C2.50889 5.12603 2.69519 4.95401 2.97444 4.89769C4.15316 4.6601 5.32764 4.39936 6.51253 4.19841C7.36764 4.05338 8.03838 4.79471 7.84939 5.67335C7.67813 6.46945 7.44285 7.2532 7.20641 8.03349C7.11153 8.34629 7.1366 8.59508 7.304 8.87895C7.74602 9.628 8.16489 10.3913 8.56834 11.1623C8.65474 11.3274 8.70913 11.5716 8.65397 11.7386C8.24435 12.9813 7.82509 14.2221 7.36301 15.4464C7.12464 16.0778 6.40684 16.1607 5.9386 15.6604C5.75076 15.4595 5.73147 15.2211 5.7793 14.9677C5.95479 14.0393 6.12219 13.109 6.3135 12.1841C6.3675 11.9233 6.31157 11.7455 6.13723 11.548C5.60419 10.9436 5.09814 10.3153 4.57397 9.7036C4.04825 9.08994 3.86697 8.42228 4.17476 7.64392C4.32248 7.27056 4.42547 6.87945 4.55314 6.48179L4.55275 6.48063Z" fill="#666666"/>
    <path d="M10.1787 0.684206C9.60752 0.840032 9.50338 0.262629 9.12808 0.112589C9.13078 0.0871325 9.13348 0.0616759 9.13657 0.0358336C9.24302 0.0288908 9.34948 0.0153911 9.45632 0.0153911C11.0686 0.0146197 12.6808 0.0157768 14.2935 0.0165482C14.3363 0.0165482 14.3795 0.0153911 14.4223 0.0177054C14.6904 0.0319765 14.8524 0.161574 14.8304 0.367927C14.8057 0.596265 14.6587 0.699635 14.43 0.695777C14.2275 0.692306 14.025 0.695006 13.7951 0.695006V3.71817C13.9957 3.71817 14.1959 3.72203 14.3957 3.71702C14.6321 3.71123 14.7999 3.80149 14.8315 4.04795C14.8581 4.25778 14.6769 4.41322 14.3995 4.41399C13.1633 4.41823 11.9272 4.41592 10.691 4.4163C10.0249 4.4163 9.35874 4.4163 8.68066 4.38699C9.18363 4.17717 9.47329 3.53496 10.1787 3.73437V0.684206ZM10.927 0.71352V3.69156H13.0631V0.71352H10.927Z" fill="#666666"/>
    <path d="M6.40138 0.0459153C5.97942 0.450393 5.5563 0.676288 5.13202 0.723602V3.70743C4.52183 3.8432 3.94636 3.96392 3.37436 4.10162C3.05229 4.17915 2.73871 4.29139 2.41781 4.37354C2.28551 4.40749 2.14395 4.41289 2.00626 4.41481C1.64099 4.41983 1.27573 4.41481 0.910464 4.41636C0.669398 4.41674 0.500072 4.3072 0.500072 4.06305C0.500072 3.81273 0.680969 3.71784 0.91625 3.71861C1.1172 3.719 1.31816 3.71861 1.53685 3.71861V0.694288C1.31777 0.694288 1.09792 0.693517 0.878065 0.694288C0.646641 0.695831 0.496215 0.582819 0.500072 0.352166C0.50393 0.123442 0.655512 0.0166016 0.887322 0.0166016C2.67044 0.0185301 4.45395 0.017373 6.23707 0.0185301C6.2768 0.0185301 6.31691 0.0312584 6.401 0.0459153H6.40138ZM4.41191 0.722059H2.26198V3.69547H4.41191V0.721673V0.722059Z" fill="#666666"/>
    <path d="M6.01308 2.09702C6.00922 1.13276 6.78179 0.359802 7.75069 0.358645C8.72036 0.357102 9.49447 1.12659 9.49524 2.0924C9.49601 3.05319 8.71997 3.83116 7.75493 3.83618C6.79645 3.84158 6.01655 3.06283 6.0127 2.09702H6.01308Z" fill="#666666"/>
    <path d="M3.83281 9.99512C4.31996 10.593 4.7778 11.1472 5.22251 11.7119C5.27111 11.7736 5.28191 11.9206 5.2418 11.9873C4.68214 12.9172 4.11901 13.8452 3.53736 14.7613C3.31327 15.1138 2.87858 15.1485 2.44118 14.9059C2.11025 14.7223 1.95057 14.3127 2.10061 13.9795C2.65217 12.7556 3.16593 11.5144 3.69473 10.2798C3.7279 10.2026 3.76802 10.1286 3.8332 9.99512H3.83281Z" fill="#666666"/>
    <path d="M8.60363 4.96517C8.63872 4.97095 8.70314 4.97558 8.76408 4.99294C9.25354 5.13256 9.73259 5.12176 10.2074 4.92274C10.3127 4.87838 10.4373 4.86411 10.5534 4.86334C11.3252 4.85833 12.0974 4.86064 12.8614 4.86064C12.9721 5.21549 12.9158 5.39677 12.6265 5.54758C11.8085 5.97495 10.9896 6.40192 10.1607 6.80653C9.97636 6.89679 9.74185 6.94731 9.54012 6.92571C9.13899 6.8829 8.74441 6.77722 8.36719 6.70123C8.4478 6.1084 8.52378 5.55298 8.60363 4.9644V4.96517Z" fill="#666666"/>
    </svg>
    
    
    
  );
};

export default RoofIcon;
