import React from "react";

const CostIcon = () => {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.52498 7.59372C3.31671 7.82283 3.31671 8.12274 3.52498 8.33102C3.73742 8.5393 4.00818 8.5393 4.24145 8.31019C5.01623 7.54374 5.78685 6.76895 6.56164 5.99833C8.03623 4.52791 9.51082 3.05748 10.9854 1.58706C11.0229 1.54957 11.0646 1.51625 11.1479 1.4371C11.1895 1.49958 11.2187 1.5704 11.2687 1.62038C13.4097 3.76562 15.555 5.91086 17.7002 8.04776C17.8043 8.1519 17.946 8.24771 18.0876 8.27687C18.3709 8.33519 18.5416 8.16023 18.6583 7.9228V7.63538C18.5666 7.51874 18.4875 7.39378 18.3834 7.28964C16.0048 4.89864 13.6222 2.5118 11.2353 0.129131C11.1978 0.0916412 11.1604 0.0541516 11.0979 0C10.7521 0.349903 10.4106 0.703971 10.0648 1.04554C7.93209 3.18245 5.79518 5.31519 3.65828 7.4521C3.61246 7.49792 3.56247 7.54374 3.52082 7.59372H3.52498Z" fill="#666666"/>
    <path d="M2.08789 6.36911C2.27951 6.54406 2.50028 6.68985 2.73771 6.86897C2.75854 6.65236 2.7752 6.46491 2.79186 6.28163C2.80436 6.14417 2.82935 6.00671 2.82102 5.87341C2.80436 5.61515 2.91683 5.41937 3.07512 5.23192C3.2709 4.99449 3.45002 4.74039 3.65829 4.46547C3.28339 4.43631 2.94599 4.39882 2.60442 4.39049C2.40864 4.38632 2.25035 4.32801 2.10039 4.20304C1.8338 3.98644 1.55471 3.78233 1.25063 3.55322C1.2298 3.79482 1.21314 4.00726 1.19647 4.2197C1.17981 4.41132 1.17565 4.60293 1.15065 4.79038C1.14232 4.86119 1.109 4.93617 1.06318 4.99865C0.834075 5.31107 0.596641 5.61515 0.338379 5.96089C0.625799 5.98588 0.879895 6.02754 1.13816 6.02337C1.50889 6.01504 1.81713 6.12334 2.09206 6.37744L2.08789 6.36911Z" fill="#666666"/>
    <path d="M4.05063 2.44502C3.98815 2.77826 3.909 3.10734 3.82569 3.47807C4.16727 3.35727 4.47135 3.23647 4.78376 3.14066C4.90873 3.10317 5.05035 3.09484 5.17949 3.11567C5.50439 3.17398 5.82514 3.25313 6.17921 3.33227C6.05841 2.9907 5.93761 2.67829 5.8418 2.36171C5.80431 2.23674 5.79598 2.09512 5.82097 1.96599C5.87929 1.64524 5.95844 1.33283 6.04175 0.97876C5.68768 1.10373 5.37526 1.22452 5.05452 1.3245C4.94622 1.35782 4.82125 1.36615 4.70878 1.34532C4.37554 1.28284 4.05063 1.19953 3.68823 1.12039C3.8132 1.47029 3.93816 1.79103 4.0423 2.11594C4.07562 2.21592 4.07979 2.33672 4.05896 2.44085L4.05063 2.44502Z" fill="#666666"/>
    <path d="M16.9425 8.30606C15.0222 6.39409 13.1019 4.48629 11.1774 2.57432C11.1608 2.55766 11.1441 2.541 11.0733 2.47852C10.9817 2.59515 10.8984 2.72012 10.7942 2.82009C8.96556 4.64042 7.1369 6.45241 5.31241 8.27274C5.24576 8.33939 5.19161 8.45602 5.19161 8.54766C5.18328 10.6471 5.18744 12.7465 5.18744 14.8501C5.18744 14.8959 5.19161 14.9417 5.19577 15H9.35712V11.8551C9.35712 10.8803 10.1486 10.0889 11.1233 10.0889C12.098 10.0889 12.8895 10.8803 12.8895 11.8551V15H17.0383C17.0383 14.9334 17.0466 14.8751 17.0466 14.8168C17.0466 12.7298 17.0466 10.6387 17.0425 8.55183C17.0425 8.46852 17.005 8.36438 16.9467 8.30606H16.9425Z" fill="#666666"/>
    </svg>
    

    
  );
};

export default CostIcon;
