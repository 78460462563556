import React from "react";

const DeclutIcon = () => {
  return (
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.6948 15.3012C0.869085 15.4792 1.08331 15.5082 1.29391 15.3775C1.34111 15.3484 1.38105 15.3121 1.42099 15.2795C3.47247 13.5475 5.52032 11.8119 7.5718 10.0763C7.61174 10.0436 7.65168 10.0073 7.68436 9.97465C7.45924 9.70959 7.24138 9.4518 7.01627 9.18311C6.98722 9.20489 6.94728 9.23031 6.9146 9.26299C4.85586 11.0022 2.79711 12.7451 0.742002 14.4843C0.433372 14.7457 0.42248 15.0144 0.6948 15.2976V15.3012Z" fill="#666666"/>
<path d="M9.51759 8.86356C9.6592 8.78368 9.82259 8.68927 9.98598 8.5985C10.5125 8.2935 11.0426 7.99576 11.5654 7.68714C12.1754 7.32404 12.3897 6.52887 12.0447 5.92976C11.6163 5.18542 10.6178 5.04381 9.99688 5.64655C9.94604 5.69375 9.90247 5.74821 9.85527 5.80268C9.36872 6.39089 8.88581 6.98273 8.41016 7.56005C8.78051 7.9994 9.13997 8.42422 9.51396 8.86719L9.51759 8.86356Z" fill="#666666"/>
<path d="M13.1345 8.29344C12.3938 8.43868 11.6495 8.57666 10.9051 8.71826C10.8543 8.72916 10.7962 8.73279 10.749 8.7582C10.4513 8.92523 10.1535 9.09951 9.82312 9.28832C9.99377 9.48439 10.1426 9.65868 10.3024 9.82933C10.3387 9.86564 10.4041 9.89106 10.4585 9.89469C11.3554 9.95642 12.2522 10.0181 13.1491 10.0726C13.5811 10.1017 13.9007 9.92737 14.0931 9.53523C14.2529 9.21207 14.2456 8.90344 14.0205 8.61297C13.7917 8.31886 13.4976 8.22083 13.1345 8.29344Z" fill="#666666"/>
<path d="M9.05324 2.92332C8.58485 2.76719 7.98938 3.08671 7.86956 3.5551C7.84777 3.63862 7.83325 3.72213 7.82235 3.80927C7.73158 4.68796 7.64081 5.56664 7.56093 6.44533C7.55366 6.51432 7.58271 6.60509 7.62628 6.66319C7.75337 6.83747 7.89497 7.00087 8.03295 7.17152C8.52676 6.6995 8.85717 6.16938 9.04598 5.54123C9.19485 5.04379 9.39818 4.5645 9.58336 4.07796C9.80122 3.49701 9.43449 3.05403 9.05324 2.92695V2.92332Z" fill="#666666"/>
<path d="M6.63115 7.46934C6.47502 7.60731 6.46776 7.70535 6.60573 7.86874C7.00877 8.34802 7.41543 8.82005 7.81847 9.29933C8.18156 9.72778 8.53739 10.1562 8.90049 10.5847C9.05662 10.7699 9.14739 10.7735 9.3362 10.621C9.51775 10.4685 9.6993 10.3196 9.86995 10.178C8.97311 9.11778 8.08353 8.06844 7.17579 6.99731C7.00514 7.14618 6.81633 7.30594 6.63115 7.46934Z" fill="#666666"/>
<path d="M11.5911 3.52972C11.1299 3.32276 10.6724 3.38448 10.2767 3.7149C10.2077 3.77299 10.1714 3.87829 10.1387 3.96906C10.0261 4.28132 9.92446 4.59722 9.81917 4.91311C9.79375 4.98936 9.76833 5.06198 9.72839 5.1818C10.618 4.5718 11.4422 4.63353 12.2483 5.30525C12.3499 5.09102 12.4226 4.8768 12.3971 4.64442C12.339 4.12519 12.0631 3.74031 11.5947 3.53335L11.5911 3.52972Z" fill="#666666"/>
<path d="M12.1322 7.90868C12.1322 7.90868 12.1249 7.92683 12.114 7.9704C12.4335 7.90867 12.7422 7.83969 13.0508 7.79248C13.2578 7.76344 13.4284 7.67266 13.5773 7.53832C14.0711 7.09171 14.111 6.28928 13.6681 5.78821C13.472 5.56672 13.2505 5.40333 12.96 5.33434C12.862 5.30892 12.8112 5.32345 12.7458 5.42874C12.5969 5.67202 12.5933 5.89713 12.6659 6.17672C12.822 6.79035 12.6478 7.34588 12.2193 7.8179C12.1903 7.85058 12.1612 7.87963 12.1322 7.91231V7.90868Z" fill="#666666"/>
<path d="M16.0576 2.46953V2.46227L15.9414 2.30977C15.5311 1.73245 14.9683 1.42019 14.3147 1.4093C14.086 1.40567 13.8863 1.43835 13.7374 1.47829C13.1274 0.712158 12.4956 0.541504 12.0563 0.541504C11.548 0.541504 11.203 0.766622 11.1631 0.792039L11.5915 1.42019C11.5915 1.42019 11.8239 1.27132 12.1471 1.27132C12.5864 1.27132 12.9967 1.54727 13.3671 2.09192L13.5522 2.3606L13.81 2.24441C13.81 2.24441 14.0642 2.13549 14.3946 2.13912C14.8158 2.14638 15.1462 2.32793 15.4077 2.69465L15.5166 2.83989C15.7925 3.25382 15.8543 3.6278 15.7054 3.98727C15.5819 4.28137 15.3641 4.45203 15.3605 4.45203L15.1063 4.64083L15.3096 4.9422C15.6691 5.47595 15.7744 5.9516 15.6255 6.35464C15.5057 6.67053 15.2733 6.83755 15.2697 6.83755L15.4839 7.15344L15.6981 7.46934C15.7453 7.43666 16.0757 7.20065 16.2609 6.73589C16.4243 6.33285 16.5006 5.69381 16.0322 4.85506C16.1302 4.7425 16.2464 4.58274 16.3335 4.38304C16.5151 3.96548 16.6168 3.29376 16.0612 2.4659L16.0576 2.46953Z" fill="#666666"/>
</svg>

  );
};

export default DeclutIcon;
